import React, { Component } from 'react';

//Child Components
import SkillsetItem from './SkillsetItem';

//SCSS
import 'scss/components/_skills-graph.scss';
import 'scss/components/_skills-description.scss';

class SkillsGraph extends Component {
    render() {
        if (this.props.skillItems.length > 0){
            const skillItems = this.props.skillItems.map((item, key) => {
                return (
                    <SkillsetItem
                        key={key}
                        index={key}
                        skill={item.fields}
                        animatingOut={this.props.animatingOut}
                        transitionDuration={this.props.transitionDuration}
                    />
                )
            });

            return (
                <div id="skills-graph">
                    <div className="graph">
                        {skillItems}
                        <div className="vertical-wrapper">
                            <span className="vertical"></span>
                            <span className="vertical"></span>
                            <span className="vertical"></span>
                            <span className="vertical"></span>
                            <span className="vertical"></span>
                        </div>
                    </div>
                    <div className="graph-key">
                        <div className="key-item rookie"><span>Rookie</span></div>
                        <div className="key-item starter"><span>Starter</span></div>
                        <div className="key-item allstar"><span>All-Star</span></div>
                        <div className="key-item legend"><span>Legend</span></div>
                        <div className="key-item goat"><span>G.O.A.T</span></div>
                    </div>
                </div>
            );
        } else {
            return (
                //TODO -- preloader
                <div><h1>LOADING</h1></div>
            )
        }
    }
}
export default SkillsGraph;




