import React, { Component } from 'react';

//SCSS
import 'scss/components/_faq.scss';

class FAQ extends Component {

    constructor(props) {
        super(props);

        this.state = {
            faqItems: [
                {
                    question: "Born in?",
                    answer: "Sydney, Australia"
                },
                {
                    question: "Living in?",
                    answer: "Inner West of Sydney"
                },

                {
                    question: "Favourite Food?",
                    answer: "Pizza, with a home-cooked steak a close second"
                },

                {
                    question: "Favourite film?",
                    answer: "Star Wars (Ep4 'Empire Strikes Back', to be precise)"
                },

                {
                    question: "Sports y/n?",
                    answer: "Love basketball - played varsity point guard at high school in NY. I'm 5'5."
                },

                {
                    question: "Cat or dog person?",
                    answer: "I grew up with dogs. Then I got a cat. Now, he's the master."
                },

                {
                    question: "Tabs or spaces?",
                    answer: "Tabs. Don't hate me."
                },

                {
                    question: "Marvel or DC?",
                    answer: "Marvel. Superman is boring and one-dimensional. Yes, we can argue about it."
                },

                {
                    question: "Favourite super hero?",
                    answer: "Wolverine. No contest."
                },

                {
                    question: "Favourite Drag Race contestant?",
                    answer: "Katya, with Adore De Lano a close second."
                },
            ]
        }
    }

    render() {

        const faqItems = this.state.faqItems.map((item, key) => {
            return (
                <div className="col-12 col-xl-6" key={key}>
                    <div className="item">
                        <p className="question">{item.question}</p>
                        <p className="answer">{item.answer}</p>
                    </div>
                </div>
            )
        });


        return (
            <div className="faq">
                <h3>Q+A:</h3>
                <div className="items-wrapper">
                    <div className="row">
                        {faqItems}
                    </div>

                </div>
            </div>
        );
    }
}
export default FAQ;
