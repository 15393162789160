import React, { Component } from 'react';
import Dropdown from 'react-dropdown';

//SCSS
import 'react-dropdown/style.css';
import 'scss/components/_skills-dropdown.scss';

class SkillDropDown extends Component {
    handleChange = (e) => {
        let skillIndex = e.value;
        this.props.onChange(skillIndex);
    };

    render() {
        if (this.props.options.length > 0){
            return (
                <div className="skill-dropdown">
                    <div className="filter-controls">
                        <div className="filter-text">
                            Filter by:
                        </div>
                        <div className="dropdown">
                            <Dropdown
                                options={this.props.options}
                                onChange={this.handleChange}
                                value={this.props.options[this.props.defaultIndex]}
                                placeholder="Select an option"
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div><h1>LOADING</h1></div>
            )
        }

    }
}
export default SkillDropDown;
