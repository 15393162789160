import React, { Component } from 'react';

//Component SCSS
import 'scss/components/_feature-img.scss';

class FeatureImage extends Component {

    render() {
        return (
            <div className="feature-img">
                <div className="row">
                    <div className="col-12">
                        <div className="aspect-block">
                            <div className="img-block">
                                <div className="img-inner">
                                    <picture>
                                        <source srcSet={`${this.props.img.file.url}?w=1180&h=560&fit=crop`} media="(min-width: 992px)" />
                                        <img srcSet={`${this.props.img.file.url}?w=600&h=360`} alt={this.props.img.title}/>
                                    </picture>
                                </div>

                                <div className="content-layer">
                                    <h2 className="tag-line lg">
                                        {this.props.text}
                                    </h2>
                                </div>

                            </div>
                        </div>
                        <h2 className="tag-line sm">
                            {this.props.text}
                        </h2>
                    </div>
                </div>
            </div>
        );
    }
}
export default FeatureImage;
