import React, { Component } from 'react';
import * as contentful from 'contentful'
import Media from 'react-media';
import MediaQueries from 'js/App/Config/MediaQueries.js';

//Config
import ContentfulConfig from '../Config/Contentful';

//Child Components
import SkillDropDown from '../Components/Skillset/SkillDropDown';
import SkillsGraph from '../Components/Skillset/SkillsGraph';
import GenericTextBlock from '../Components/GenericTextBlock';
import ReactTooltip from 'react-tooltip';

//SCSS
import 'scss/views/_skillset.scss';

class Skillset extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allSkillCategories: [],
            allSkillItems: [],

            selectedCategory: null,
            selectedSkills: [],

            transitionDuration: 750,
            animatingOut: true,
            animatingIn: false,

            dropDownOptions: [],
            defaultIndex: 0
        }
    }

    fetchSkillCategories = () => {
        const contentfulCient = contentful.createClient(ContentfulConfig);

        //Fetch Skill Category objects
        contentfulCient.getEntries({
            'content_type': 'accSkillCategory',
            order: 'fields.order'
        })
            .then(entries => {
                this.populateDropdownOptions(entries.items);
                this.setState({
                    allSkillCategories: entries.items,
                    selectedCategory: entries.items[this.state.defaultIndex]
                });

                this.fetchSkills();
            });
    };

    fetchSkills = () => {
        const contentfulCient = contentful.createClient({
            space: 'mzij3xo7fi1p',
            accessToken: 'rjrmXcp_tc2Rrxkw40D6Lig84t3cQMcK6mb-sufbfPU'
        });

        //Fetch SKill objects
        contentfulCient.getEntries({
            'content_type': 'accSkill',
            order: '-fields.rating'

        })
            .then(entries => {
                this.setState({
                    allSkillItems: entries.items,
                });

                this.selectSkillCategory(this.state.defaultIndex);
                this.setSelectedSkills(this.state.allSkillCategories[this.state.defaultIndex].fields.categoryName);
            });
    };

    populateDropdownOptions = (categoriesArr) => {

        let dropDownOptions = [];
        /*
        Populate the dropdown options
        Format needs to be quite specific for the 3rd party dropdown component
        */
        categoriesArr.forEach(function (item, index) {
            let obj = {
                value: index,
                label: item.fields.categoryName
            };

            dropDownOptions.push(obj);
        });

        this.setState({
            dropDownOptions: dropDownOptions
        });
    }

    selectSkillCategory = (selectedSkillIndex) => {
        let selectedCategory = this.state.allSkillCategories[selectedSkillIndex];
        let selectedCategoryName = selectedCategory.fields.categoryName;

        if (selectedCategoryName === this.state.selectedCategory.fields.categoryName){
            return;
        }

        this.setState({
            animatingOut: false,
        });

        setTimeout(() => {
            this.setSelectedSkills(selectedCategoryName);
            this.setState({
                animatingOut: true,
                selectedCategory: selectedCategory
            });
        }, this.state.transitionDuration);
    };

    setSelectedSkills = (categoryName) => {
        const childSkills = this.state.allSkillItems.filter(item => item.fields.skillCategory.fields.categoryName === categoryName);

        this.setState({
            selectedSkills: childSkills,
        });
    };

    componentDidMount(){
        this.fetchSkillCategories();
    }

    render() {
        let textDescRender = null;
        const toolTipContent = <div className="tooltip-wrap">
            <div className="legend-item">
                <div className="legend-name">
                    <span>Rookie</span>
                </div>
                <p className="desc">
                    I've had minimal exposure to this technology.
                </p>
            </div>
            <div className="legend-item">
                <div className="legend-name">
                    <span>Starter</span>
                </div>
                <p className="desc">
                    I've used this technology for some projects and can confidently utilize it
                </p>
            </div>

            <div className="legend-item">
                <div className="legend-name">
                    <span>All Star</span>
                </div>
                <p className="desc">
                    A skill I'm quite proficient in.
                </p>
            </div>

            <div className="legend-item">
                <div className="legend-name">
                    <span>Legend</span>
                </div>
                <p className="desc">
                    One of my strongest skills, this is my 'day to day'.
                </p>
            </div>

            <div className="legend-item">
                <div className="legend-name">
                    <span>G.O.A.T</span>
                </div>
                <p className="desc">
                    Greatest of all time! Ok, probably not, but very proficient in this skill, and you
                    can trust me to lead a project in this area.
                </p>
            </div>
        </div>;

        if (this.state.selectedCategory && this.state.selectedCategory.fields.categoryDescription){
            textDescRender =
                <GenericTextBlock
                    contentNodes={this.state.selectedCategory.fields.categoryDescription.content}
                />
        }

        return (
            <div id="skillset" className="content-page">
                <div className="inner-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="intro-row">
                                    <h1>Skills &amp; Profficiencies</h1>
                                    <p>
                                        Although a dedicated front end developer - with a comprehensive skillset
                                        in all the expected front end skills -
                                        over the course of my career I've
                                        developed a wide array of skills across a variety of disciplines, both technical
                                        and creative.
                                        Additionally, I'm comfortable working within the context of a wide range
                                        of industry leading CMS's, such as Sitecore, Kentico and Umbraco.
                                    </p>
                                    <div className="faux-border"></div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-5 offset-lg-1 order-lg-2">
                                <SkillDropDown
                                    options={this.state.dropDownOptions}
                                    onChange={this.selectSkillCategory}
                                    defaultIndex={this.state.defaultIndex}
                                />
                                <div className="text-desc-block d-none d-lg-block">
                                    {textDescRender}
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 order-lg-1">
                                <div className="selected-skill-row">

                                    {this.state.selectedCategory &&
                                        <p className="skill-name">{this.state.selectedCategory.fields.categoryName}</p>
                                    }

                                    <button className="btn-tooltip" data-tip>
                                        <span>?</span>
                                    </button>
                                </div>

                                <SkillsGraph
                                    skillItems={this.state.selectedSkills}
                                    animatingOut={this.state.animatingOut}
                                    animatingIn={this.state.animatingIn}
                                    transitionDuration={this.state.transitionDuration}
                                />
                            </div>

                            <div className="col-12 d-lg-none">
                                <div className="text-desc-block">
                                    {textDescRender}
                                </div>
                            </div>

                            <div className="key-legend">
                                <Media queries={MediaQueries}>
                                    {matches =>
                                        matches.lg || matches.xl ? (
                                            <ReactTooltip
                                                effect="solid"
                                                place="right"
                                                className="key-legend-tooltip"
                                                event="click"
                                                eventOff="mousemove"
                                                delayHide={1000}
                                                offset={{top: -160, left: 0}}
                                            >
                                                {toolTipContent}
                                            </ReactTooltip>
                                        ) : (
                                            <ReactTooltip
                                                effect="solid"
                                                place="left"
                                                className="key-legend-tooltip"
                                                event="click"
                                                eventOff="mousemove"
                                                delayHide={1000}
                                                offset={{top: -160, left: -10}}
                                            >
                                                {toolTipContent}
                                            </ReactTooltip>
                                        )
                                    }
                                </Media>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Skillset;
