import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

//Child Components
import PortfolioDefault from '../Components/PortfolioDefault';
import PortfolioDetail from '../Components/PortfolioDetail';


//Component SCSS
import 'scss/components/_portfolio-item.scss';

class PortfolioItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDetail: true,
            sizeLg: window.matchMedia("(min-width: 992px)").matches
        };
    }

    showDetailView = () => {
        this.props.setActiveSlide(this.props.slideId)
    };

    closeDetailView = () => {
        this.props.setActiveSlide(this.props.slideId)
    };

    shouldComponentUpdate(nextProps, nextState){

        if(nextProps.isSliderOpen !== this.props.isSliderOpen){
            if(nextProps.isSliderOpen === false){

                setTimeout(() => {

                    this.setState({
                        showDetail: true,
                    });
                }, 500);

            } else {

                this.setState({
                    showDetail: false,
                });
            }
        }

        return true;
    }

    componentDidMount() {
        const handler = e => this.setState({sizeLg: e.matches});
        window.matchMedia("(min-width: 992px)").addListener(handler);
    }

    render() {
        let bgImageStyle = {
            backgroundImage: 'url(' + this.props.content.fields.mobileBackgroundImage.fields.file.url + ')',
        };

        if (this.state.sizeLg){
            bgImageStyle = {
                backgroundImage: 'url(' + this.props.content.fields.backgroundImage.fields.file.url + ')',
            };
        }

        return (
            <div className={`portfolio-item ${this.props.isActive ? 'active' : ''} `}>
                <div className="bg-image" style={bgImageStyle}></div>
                <div className="inner">
                    <CSSTransition
                        timeout={350}
                        in={this.state.showDetail}
                        unmountOnExit
                        classNames="fade"
                    >
                        <PortfolioDefault
                            content={this.props.content}
                            showDetailView={this.showDetailView}
                        />
                    </CSSTransition>

                    <CSSTransition
                        timeout={350}
                        in={this.props.isActive}
                        unmountOnExit
                        classNames="fade"
                    >
                        <PortfolioDetail
                            content={this.props.content}
                            closeDetailView={this.closeDetailView}
                        />
                    </CSSTransition>

                </div>
            </div>
        );
    }
}
export default PortfolioItem;
