// eslint-disable-next-line import/no-webpack-loader-syntax
import vertex from '!!raw-loader!glslify-loader!../Shaders/vertex.glsl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import fragment from '!!raw-loader!glslify-loader!../Shaders/fragment.glsl';

export default class Sketch {
    constructor(args) {
        this.container = document.getElementById(args.elementId);
        this.canvas = document.createElement('canvas');
        this.container.appendChild(this.canvas);
        this.gl = this.canvas.getContext('webgl');
        this.ratio = window.devicePixelRatio;
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.mouseX = 0;
        this.mouseY = 0;
        this.parallax = args.parallax;
        this.animating = true;
        this.mouseTargetX = 0;
        this.mouseTargetY = 0;

        this.imageOriginal = args.img;
        this.imageDepth = args.imgDepth;

        this.vth = args.horizontalThreshold;
        this.hth = args.verticalThreshold;
        this.movementThrottle = args.movementThrottle;

        this.imageURLs = [
            this.imageOriginal,
            this.imageDepth
        ];
        this.textures = [];

        this.startTime = new Date().getTime(); // Get start time for animating

        this.createScene();
        this.addTexture();
        this.mouseMove();
    }

    addShader( source, type ) {
        let shader = this.gl.createShader( type );
        this.gl.shaderSource( shader, source );
        this.gl.compileShader( shader );
        let isCompiled = this.gl.getShaderParameter( shader, this.gl.COMPILE_STATUS );

        if ( !isCompiled ) {
            throw new Error( 'Shader compile error: ' + this.gl.getShaderInfoLog( shader ) );
        }
        this.gl.attachShader( this.program, shader );
    }

    resizeHandler() {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.width = this.container.offsetWidth;
        // this.height = this.width/this.aspect;
        this.height = this.container.offsetHeight;

        this.canvas.width = this.width*this.ratio;
        this.canvas.height = this.height*this.ratio;
        this.canvas.style.width = this.width + 'px';
        this.canvas.style.height = this.height + 'px';
        let a1,a2;
        if(this.height/this.width<this.imageAspect) {
            a1 = 1;
            a2 = (this.height/this.width) / this.imageAspect;
        } else{
            a1 = (this.width/this.height) * this.imageAspect ;
            a2 = 1;
        }
        this.uResolution.set( this.width, this.height, a1, a2 );
        this.uRatio.set( 1/this.ratio );
        this.uThreshold.set( this.hth, this.vth );
        this.gl.viewport( 0, 0, this.width*this.ratio, this.height*this.ratio );
    }

    resize() {
        this.resizeHandler();
        window.addEventListener( 'resize', this.resizeHandler.bind(this) );
    }

    createScene() {
        // create program
        this.program = this.gl.createProgram();
        // add shaders
        this.addShader( vertex, this.gl.VERTEX_SHADER );
        this.addShader( fragment, this.gl.FRAGMENT_SHADER );

        // link & use program
        this.gl.linkProgram( this.program );
        this.gl.useProgram( this.program );

        // create fragment uniforms
        this.uResolution = new Uniform( 'resolution', '4f' , this.program, this.gl );
        this.uMouse = new Uniform( 'mouse', '2f' , this.program, this.gl );
        this.uTime = new Uniform( 'time', '1f' , this.program, this.gl );
        this.uRatio = new Uniform( 'pixelRatio', '1f' , this.program, this.gl );
        this.uThreshold = new Uniform( 'threshold', '2f' , this.program, this.gl );
        // create position attrib
        this.billboard = new Rect( this.gl );
        this.positionLocation = this.gl.getAttribLocation( this.program, 'a_position' );
        this.gl.enableVertexAttribArray( this.positionLocation );
        this.gl.vertexAttribPointer( this.positionLocation, 2, this.gl.FLOAT, false, 0, 0 );
    }

    addTexture() {
        let that = this;
        loadImages(this.imageURLs, that.start.bind(this));
    }

    start(images) {
        let that = this;
        let gl = that.gl;

        // connect images
        this.imageAspect = images[0].naturalHeight/images[0].naturalWidth;
        for (var i = 0; i < images.length; i++) {


            let texture = gl.createTexture();
            gl.bindTexture(gl.TEXTURE_2D, texture);

            // Set the parameters so we can render any size image.
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

            // Upload the image into the texture.
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, images[i]);
            this.textures.push(texture);
        }

        // lookup the sampler locations.
        let u_image0Location = this.gl.getUniformLocation(this.program, 'image0');
        let u_image1Location = this.gl.getUniformLocation(this.program, 'image1');

        // set which texture units to render with.
        this.gl.uniform1i(u_image0Location, 0); // texture unit 0
        this.gl.uniform1i(u_image1Location, 1); // texture unit 1

        // Set each texture unit to use a particular texture.
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[0]);
        this.gl.activeTexture(this.gl.TEXTURE1);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[1]);


        // start application
        this.resize();
        this.render();
    }

    mouseMove() {
        let that = this;
        document.addEventListener('mousemove', function(e) {
            let halfX = that.windowWidth/2;
            let halfY = that.windowHeight/2;

            let calculatedX = (halfX - e.clientX)/halfX;
            let calculatedY = (halfY - e.clientY)/halfY;

            if (that.parallax === true){
                if (calculatedX < 0 ){
                    calculatedX = Math.abs(calculatedX);
                } else if (calculatedX > 0){
                    calculatedX = -Math.abs(calculatedX);
                }

                if (calculatedY < 0 ){
                    calculatedY = Math.abs(calculatedY);
                } else if (calculatedY > 0){
                    calculatedY = -Math.abs(calculatedY);
                }
            }

            that.mouseTargetX = calculatedX;
            that.mouseTargetY = calculatedY;
        });
    }

    render() {
        let now = new Date().getTime();
        let currentTime = ( now - this.startTime ) / 1000;
        this.uTime.set( currentTime );
        // inertia
        this.mouseX += (this.mouseTargetX - this.mouseX)*this.movementThrottle;
        this.mouseY += (this.mouseTargetY - this.mouseY)*this.movementThrottle;

        this.uMouse.set( this.mouseX, this.mouseY );

        // render
        this.billboard.render( this.gl );

        if(this.animating === true){
            requestAnimationFrame( this.render.bind(this) );
        }
    }

    pause(){
        this.animating = false;
        console.log('pause');
    }

    play(){
        this.animating = true;
        requestAnimationFrame( this.render.bind(this) );
        console.log('play');
    }
}

function loadImage(url, callback) {
    let image = new Image();
    image.src = url;
    image.onload = callback;
    return image;
}
function loadImages(urls, callback) {
    let images = [];
    let imagesToLoad = urls.length;

    // Called each time an image finished loading.
    let onImageLoad = function() {

        --imagesToLoad;
        // If all the images are loaded call the callback.
        if (imagesToLoad === 0) {
            callback(images);
        }
    };

    for (var ii = 0; ii < imagesToLoad; ++ii) {
        var image = loadImage(urls[ii], onImageLoad);
        images.push(image);
    }
}
function Uniform( name, suffix, program,gl ) {
    this.name = name;
    this.suffix = suffix;
    this.gl = gl;
    this.program = program;
    this.location = gl.getUniformLocation( program, name );
}

Uniform.prototype.set = function( ...values ) {
    let method = 'uniform' + this.suffix;
    let args = [ this.location ].concat( values );
    this.gl[ method ].apply( this.gl, args );
};

// ----- Rect ----- //
function Rect( gl ) {
    var buffer = gl.createBuffer();
    gl.bindBuffer( gl.ARRAY_BUFFER, buffer );
    gl.bufferData( gl.ARRAY_BUFFER, Rect.verts, gl.STATIC_DRAW );
}

Rect.verts = new Float32Array([
    -1, -1,
    1, -1,
    -1, 1,
    1, 1,
]);

Rect.prototype.render = function( gl ) {
    gl.drawArrays( gl.TRIANGLE_STRIP, 0, 4 );
};