import React, { Component } from 'react';

//SCSS
import 'scss/components/_social-tiles.scss';

class SocialTiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            links: [
                {
                    tileClass: "youtube",
                    iconClass: "icon-youtube2",
                    url: "https://www.youtube.com/channel/UC_BNgs_p-cAkERC6pPE-O0g",
                },

                {
                    tileClass: "twitter",
                    iconClass: "icon-twitter",
                    url: "https://twitter.com/Grumpy_OldBen",
                },

                {
                    tileClass: "instagram",
                    iconClass: "icon-instagram",
                    url: "https://www.instagram.com/allcitycreative/",
                },

                {
                    tileClass: "twitch",
                    iconClass: "icon-twitch",
                    url: "https://www.twitch.tv/grumpyoldfighters",
                },
            ]
        }
    }

    render() {
        const linkTiles = this.state.links.map((item, key) => {
            return (
                <div className="col-6 col-lg-3" key={key}>
                    <a className={`${item.tileClass}`} href={item.url} target="_blank" rel="noopener noreferrer">
                        <span className={`icon ${item.iconClass}`}></span>
                    </a>
                </div>
            )
        });
        return (
            <div>
                <section className="social-tiles">
                    <div className="row">
                        <div className="col-12">
                            <h3>Elsewhere on the 'toobs:</h3>
                        </div>
                        {linkTiles}
                    </div>
                </section>
            </div>
        );
    }
}
export default SocialTiles;
