import React from 'react';
import ReactDOM from 'react-dom';
import './scss/screen.scss';
import AppRouter from './js/App/AppRouter.jsx';

ReactDOM.render(
    <AppRouter
        online={true}
        holdingPage={false}
    />,
    document.getElementById('root')
);
