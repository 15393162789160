import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

//SCSS
import 'scss/components/_skill-item.scss';

class SkillsetItem extends Component {

    render() {
        return (
            <CSSTransition
                timeout={this.props.transitionDuration}
                in={this.props.animatingOut}
                unmountOnExit
            >
                <div className="skill-item" data-rating={this.props.skill.rating}>
                    <span className="label">{this.props.skill.title}</span>
                </div>
            </CSSTransition>
        );
    }
}
export default SkillsetItem;
