import React, { Component } from 'react';

//SCSS
import 'scss/utilities/_text-preloader.scss';

class TextPreloader extends Component {

    render() {

        return (
            <div id="text-preloader">
                <div className="inner">
                    <p>Loading</p>
                </div>
            </div>
        );
    }
}
export default TextPreloader;
