import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Child components
import SecondaryNavLinks from "./SecondaryNavLinks.jsx";

//Component SCSS
import 'scss/components/_primary-nav.scss';


class PrimaryNav extends Component {

    handleLinkClick = () => {
        this.props.forceCloseNav();
    };

    render() {

        return (
            <nav id="primary-nav">
                <div className="bg-shape"></div>
                <div className="inner">
                    <ul>
                        <li>
                            <Link to="/latest-work" onClick={this.handleLinkClick}>
                                Latest Work
                            </Link>
                        </li>
                        <li>
                            <Link to="/scribbles" onClick={this.handleLinkClick}>
                                Scribbles
                            </Link>
                        </li>
                        <li>
                            <Link to="/skillset" onClick={this.handleLinkClick}>
                                Skillset
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={this.handleLinkClick}>
                                About Me
                            </Link>
                        </li>
                    </ul>
                    <SecondaryNavLinks
                        toggleContactForm={this.props.toggleContactForm}
                    />
                </div>

            </nav>
        );
    }
}
export default PrimaryNav;
