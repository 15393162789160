import React, { Component } from 'react';

//SCSS
import 'scss/components/_currently.scss';

class Currently extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentItems: [
                {
                    iconClass: "icon-pacman",
                    title: "Playing:",
                    desc: "Street Fighter V, Ghost of Tsushima"
                },
                {
                    iconClass: "icon-headphones",
                    title: "Listening to:",
                    desc: "Wolf Alice, Solar Fields"
                },
                {
                    iconClass: "icon-tv",
                    title: "Watching:",
                    desc: "Yellowstone, Ru Paul's Drag Race"
                },
                {
                    iconClass: "icon-paint-format",
                    title: "Painting:",
                    desc: "Rebel Troopers, Star Wars Legion"
                },
                {
                    iconClass: "icon-dice",
                    title: "Playing (analogue):",
                    desc: "Star Wars Legion, Flesh and Blood"
                },

                {
                    iconClass: "icon-trophy",
                    title: "SFV Rank:",
                    desc: "Super Silver"
                }
            ]
        }
    }

    render() {

        const currentItems = this.state.currentItems.map((item, key) => {
            return (
                <div className="item" key={key}>
                    <div className="logo">
                        <span className={`icon ${item.iconClass}`}></span>
                    </div>
                    <div className="content">
                        <p className="title">{item.title}</p>
                        <p className="desc">{item.desc}</p>
                    </div>
                </div>
            )
        });


        return (
            <div className="currently">
                <h3>Currently I'm:</h3>
                <div className="items-wrapper">
                    {currentItems}
                </div>
            </div>
        );
    }
}
export default Currently;
