import React, { Component } from 'react';
import {
    HashRouter as Router,
    // BrowserRouter as Router,
    Route,
} from 'react-router-dom';

import {
    CSSTransition
} from "react-transition-group";

//Utils
import ScrollToTop from './Utilities/ScrollToTop';

//Views
import HomeSplash from './Views/HomeSplash';
import Portfolio from './Views/Portfolio';
import About from './Views/About';
import Scribbles from './Views/Scribbles';
import Skillset from './Views/Skillset';
import PortfolioDetail from './Components/PortfolioDetail';

//Components
import GlobalHeader from './Components/GlobalHeader';
import ContactForm from './Components/ContactForm';
import TransitionMask from './Components/TransitionMask';

class AppRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            transitionDuration: 1000,
            showContactForm: false,
            pauseWebGl: false
        }
    }

    toggleContactForm = () => {
        this.setState({showContactForm: ! this.state.showContactForm});
    };

    transitionStart = () => {
        this.setState({pauseWebGl: true});
    };

    transitionEnd = () => {
        this.setState({pauseWebGl: false});
    };

    render() {

        return (
            <div id='master-container'>

                <Router>
                    <GlobalHeader
                        toggleContactForm={this.toggleContactForm}
                    />
                    <main id='content-wrapper'>
                        <Route exact path="/">
                            {({ match }) => (
                                <CSSTransition
                                    in={match != null}
                                    timeout={this.state.transitionDuration}
                                    classNames="page"
                                    unmountOnExit
                                >
                                    <HomeSplash
                                        transitionStarted={this.state.pauseWebGl}
                                    />
                                </CSSTransition>
                            )}
                        </Route>

                        <Route exact path="/latest-work">
                            {({ match }) => (
                                <CSSTransition
                                    in={match != null}
                                    timeout={this.state.transitionDuration}
                                    classNames="page"
                                    unmountOnExit
                                >
                                    <Portfolio/>
                                </CSSTransition>
                            )}
                        </Route>

                        <Route exact path="/about">
                            {({ match }) => (
                                <CSSTransition
                                    in={match != null}
                                    timeout={this.state.transitionDuration}
                                    classNames="page"
                                    unmountOnExit
                                >
                                    <About/>

                                </CSSTransition>
                            )}
                        </Route>

                        <Route exact path="/scribbles">
                            {({ match }) => (
                                <CSSTransition
                                    in={match != null}
                                    timeout={this.state.transitionDuration}
                                    classNames="page"
                                    unmountOnExit
                                >
                                    <Scribbles/>

                                </CSSTransition>
                            )}
                        </Route>

                        <Route exact path="/skillset">
                            {({ match }) => (
                                <CSSTransition
                                    in={match != null}
                                    timeout={this.state.transitionDuration}
                                    classNames="page"
                                    unmountOnExit
                                >
                                    <Skillset/>
                                </CSSTransition>
                            )}
                        </Route>

                        <Route exact path="/portfolio-detail">
                            {({ match }) => (
                                <CSSTransition
                                    in={match != null}
                                    timeout={this.state.transitionDuration}
                                    classNames="page"
                                    unmountOnExit
                                >
                                    <PortfolioDetail/>
                                </CSSTransition>
                            )}
                        </Route>
                    </main>
                    <ScrollToTop/>

                    <TransitionMask
                        transitionDuration={this.state.transitionDuration}
                        transitionStart={this.transitionStart}
                        transitionEnd={this.transitionEnd}
                    />

                    <CSSTransition
                        timeout={500}
                        in={this.state.showContactForm}
                        unmountOnExit
                        classNames="fade"
                    >
                        <ContactForm
                            toggleContactForm={this.toggleContactForm}
                        />
                    </CSSTransition>
                </Router>
            </div>
        );
    }
}
export default AppRouter;
