import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Media from 'react-media';
import MediaQueries from 'js/App/Config/MediaQueries.js';
import { Link } from 'react-router-dom';

//Child Components
import WebGLImage from 'js/App/Components/WebGLImage.jsx';

//Assets
import imgMain from 'imgs/city-sml.jpg';
import imgMap from 'imgs/city-map-sml.jpg';

//SCSS
import 'scss/views/_home-splash.scss';


class HomeSplash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rendered: false,
            renderDelay: 250,
            renderDelayLong: 2000
        };

        this.setRenderedState = this.setRenderedState.bind(this);
    }

    /*
    TODO
    I need a different render delay between initial first visit, and post transition visit
    */
    setRenderedState(){
        setTimeout(() => {
            this.setState({ rendered: true });
        }, this.state.renderDelay);
    }

    componentDidMount(){
        this.setRenderedState();
    }

    render() {
        return (
            <div id="home-splash">
                 <div className={`page ${this.state.rendered ? 'rendered' : ''}`}>

                     <Media queries={MediaQueries}>
                         {matches =>
                              matches.lg || matches.xl ? (
                                 <div className="web-gl-layer">
                                     <WebGLImage
                                         elementId="webgl-image"
                                         imageOriginal={imgMain}
                                         imageDepth={imgMap}
                                         horizontalThreshold={35}
                                         verticalThreshold={15}
                                         movementThrottle={0.02}
                                         parallax={true}
                                         transitionStarted={this.props.transitionStarted}
                                     />
                                 </div>
                             ) : (
                                 <div>
                                 </div>
                             )
                         }
                     </Media>
                     <div className="content-layer">
                         <div className="intro-text-block">
                             <div className="title-block">
                                 <p className="title">All City Creative</p>
                             </div>
                             <div className="desc-block">
                                 <p className="desc">is Ben Watson - technical lead, front end developer and digital creative.</p>
                             </div>
                             <div className="cta-row">
                                 <Link className="portfolio-link btn-angled" to="/latest-work">
                                     <div className="inner">
                                        <span className="txt">View Latest work</span>
                                        <span className="icon icon-arrow-right2"></span>
                                     </div>
                                 </Link>
                             </div>
                         </div>
                     </div>
                 </div>
            </div>
        );
    }
}

export default withRouter(HomeSplash);
