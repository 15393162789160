import React, { Component } from 'react';

//Component SCSS
import 'scss/components/_text-img-block.scss';

class TextAndImgBlock extends Component {

    render() {
        const content = this.props.blockContent;
        const img = content.featureImage.fields;

        let textBlock =
            <div className="txt-block">
                <p>{content.titleText}</p>
                <p>{content.mainText}</p>
            </div>;

        let imgBlock =
            <div className="img-block">
                <picture>
                    <source srcSet={`${img.file.url}?w=600&h=600&fit=fill&fl=progressive`} media="(min-width: 992px)" />
                    <img srcSet={`${img.file.url}?w=400&h=270&fit=fill&fl=progressive`} alt={img.title}/>
                </picture>
            </div>;

        let colStructure =
            <div className="row">

                <div className="col-12 col-lg-5 order-lg-2 offset-lg-1">
                    {imgBlock}
                </div>

                <div className="col-12 col-lg-5 offset-lg-1 order-lg-1 align-self-center">
                    {textBlock}
                </div>
            </div>;

        if(content.imageOnRight === false){

            colStructure =
                <div className="row">
                    <div className="col-12 col-lg-5">
                        {imgBlock}
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-1 order-lg-2 align-self-center">
                        {textBlock}
                    </div>
                </div>;
        }
        return (
            <div className="text-img-block">
                {colStructure}
            </div>

        );
    }
}
export default TextAndImgBlock;
