import React, { Component } from 'react';
import SimpleBar from 'simplebar-react';

import { CSSTransition } from 'react-transition-group';
import 'simplebar/dist/simplebar.min.css';

//Child Components
import FeatureImage from '../Components/PortfolioContent/FeatureImage';
import TextAndImgBlock from '../Components/PortfolioContent/TextAndImgBlock';
import TabbedContent from '../Components/PortfolioContent/TabbedContent';

//Component SCSS
import 'scss/components/_portfolio-detail.scss';

class PortfolioDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            animateTime: 500,
            showDetail: false,
        };
    }

    handleBtnClick = () =>{
        this.setState({
            showDetail: false
        });

        setTimeout(() => {
            this.props.closeDetailView();
        }, this.state.animateTime);

    };

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                showDetail: true
            });
        }, this.state.animateTime);
    }

    render() {
        let fields = this.props.content.fields;
        let txtImgBlocks;
        if(fields.textAndImageBlocks){
            txtImgBlocks = fields.textAndImageBlocks.map((block, key) =>
                <TextAndImgBlock key={key} blockContent={block.fields}/>
            );
        }

        return (
            <div className="portfolio-detail">

                <CSSTransition
                    timeout={this.state.animateTime}
                    in={this.state.showDetail}
                    unmountOnExit
                    classNames="fade-up"
                >
                    <SimpleBar
                        autoHide={true}
                        style={{
                            maxHeight: '100vh',
                        }}
                    >
                        <div className="detail-inner-wrap">
                            <div className="detail-content-layer">
                                <div className="container-fluid">
                                    <div className="title-row">
                                        <div className="row">
                                            <div className="col-12 col-lg-10">
                                                <div className="flex-wrap d-flex">
                                                    <h1 className="item-title order-2 order-lg-1">
                                                        {fields.title}
                                                    </h1>
                                                    <p className="item-label order-1 order-lg-2">
                                                        {fields.workType}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-2 logo-col">
                                                <img className="item-logo" src={fields.clientLogo.fields.file.url} alt={fields.clientLogo.fields.title}/>
                                            </div>
                                        </div>
                                    </div>

                                    <FeatureImage
                                        img={fields.featureImage.fields}
                                        text={fields.featureImageText}
                                    />

                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1">
                                            <div className="intro-block">
                                                <p>
                                                    {fields.introText}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {txtImgBlocks}

                                    {fields.tabMyRole.content.length > 0 &&
                                        <TabbedContent
                                            tabs={[
                                                {
                                                    title: 'My Role',
                                                    content: fields.tabMyRole.content
                                                },
                                                {
                                                    title: 'Tools & Tech',
                                                    content: fields.tabTechStack.content
                                                }
                                            ]}
                                        />
                                    }

                                    {fields.url &&

                                    <div className="cta-row">
                                        <div className="row">
                                            <div className="col-10 offset-1">
                                                <a className="btn-angled" href={fields.url} target="_blank"
                                                   rel="noopener noreferrer">
                                                    <div className="inner">
                                                        <span className="txt">Visit Website</span>
                                                        <span className="icon icon-arrow-right2"></span>

                                                    </div>
                                                </a>

                                                {fields.showDisclaimer === true &&
                                                <p className="disclaimer">
                                                    * Please note: this project has been moved inhouse or is otherwise
                                                    being maintained by other parties.<br/>
                                                    Some code or design elements may have been changed and may not
                                                    represent my work.
                                                </p>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </SimpleBar>
                </CSSTransition>

                <button className="btn-back" onClick={this.handleBtnClick}>
                    <span className="icon icon-arrow-left2"></span>
                    <span className="txt">Back</span>
                </button>
            </div>
        );
    }
}
export default PortfolioDetail;
