import React, { Component } from 'react';
import SwiperCore, { Navigation, Pagination, EffectFade} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//Child Components
import AboutContentIntro from '../Components/About/AboutContentIntro';
import Testimonials from '../Components/About/Testimonials';
import QuickFacts from '../Components/About/QuickFacts';
import PreferredTools from '../Components/About/PreferredTools';
import SocialTiles from '../Components/About/SocialTiles';

//SCSS
import 'scss/views/_about.scss';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFade]);

class About extends Component {
    constructor(props) {
        super(props);
        this.Swiper = React.createRef();

        this.state = {
            activeTabId: 0,
            titles: ['About All City + Ben', 'Testimonials', 'Quick Facts'],
            tabs: [
                {
                    text: "The Blurb",
                    iconClass: "icon-bubble"
                },
                {
                    text: "What's the T?",
                    iconClass: "icon-mug"
                },
                {
                    text: "Quick Facts",
                    iconClass: "icon-checkmark"
                }
            ]
        }
    }

    setActiveSlide = (slideIndex) =>{
        this.Swiper.slideTo(slideIndex.key);
        this.setState({
            activeTabId: slideIndex.key,
        });
    };

    setRef(obj){
        this.Swiper = obj;
    }

    render() {
        const titleText = this.state.titles[this.state.activeTabId];
        const tabButtons = this.state.tabs.map((item, key) => {
            let isActive = false;
            if (key === this.state.activeTabId){
                isActive = true;
            }
            return (
                <li key={key}>
                    <button className={`btn-tab ${isActive ? 'active' : ''}`} onClick={() => this.setActiveSlide({key})}>
                        <span className={`icon ${item.iconClass}`}></span>
                        <span className="txt">{item.text}</span>
                    </button>
                </li>
            )
        });

        return (
            <div id="about">
                <div className="content-page">
                    <div className="inner-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="title-block">
                                    <h2>{titleText}</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="about-tabs">
                                    <ul>
                                        {tabButtons}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">

                                <Swiper
                                    slidesPerView={1}
                                    effect={"fade"}
                                    fadeEffect={{ crossFade: true }}
                                    onSwiper={(swiper) => {
                                        this.setRef(swiper);
                                    }}
                                >

                                    <SwiperSlide>
                                        <AboutContentIntro/>
                                        <PreferredTools/>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <Testimonials/>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <QuickFacts/>
                                        <SocialTiles/>
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default About;
