import React, { Component } from 'react';

//Component SCSS
import 'scss/components/_portfolio-default.scss';

class PortfolioDefault extends Component {

    handleBtnClick = () =>{
        this.props.showDetailView();
    };

    render() {
        let fields = this.props.content.fields;

        return (
            <div className="portfolio-default">
                <div className="logo-name-block">
                    <img className="item-logo" src={fields.clientLogo.fields.file.url} alt={fields.clientLogo.fields.title}/>
                </div>

                <button className="btn-angled btn-view-detail--sm" onClick={this.handleBtnClick}>
                    <div className="inner">
                        <span className="txt">View</span>
                        <span className="icon icon-arrow-right2"></span>
                    </div>
                </button>

                <button className="btn-view-detail btn-view-detail--lg" onClick={this.handleBtnClick}>
                </button>
            </div>
        );
    }
}
export default PortfolioDefault;
