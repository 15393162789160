import React, { Component } from 'react';

//Child Components
import GenericTextBlock from '../../Components/GenericTextBlock';

//Component SCSS
import 'scss/components/_tabbed-content.scss';

class TabbedContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        }
    }

    setActiveTab = (tabIndex) => {
        this.setState({
            activeTabIndex: tabIndex
        });
    }

    render() {

        const tabBtns = this.props.tabs.map((tabItem, key) => {
            return (
                <button className={`btn-tab ${ key === this.state.activeTabIndex ? 'active' : ''}`} key={key} onClick={ () =>this.setActiveTab(key)}>
                    {tabItem.title}
                </button>
            )
        });

        const tabContent = this.props.tabs.map((tabItem, key) => {
            return (
                <div className={`tab-content ${ key === this.state.activeTabIndex ? 'active' : ''}`} key={key}>
                    <GenericTextBlock contentNodes={tabItem.content}/>
                </div>
            )
        });
        return (
            <div className="tabbed-content">
                <div className="row">
                    <div className="col-12">
                        <div className="tab-btn-block">
                            {tabBtns}
                        </div>
                        <div className="tab-content-block">
                            {tabContent}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TabbedContent;
