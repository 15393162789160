import React, { Component } from 'react';

//SCSS
import 'scss/utilities/_bar-preloader.scss';

class BarPreloader extends Component {

    render() {

        return (
            <div className="bar-preloader"></div>
        );
    }
}
export default BarPreloader;
