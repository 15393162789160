import React, { Component } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as contentful from 'contentful'

//Config
import ContentfulConfig from '../Config/Contentful';

//Child Components
import TextPreloader from '../Utilities/TextPreloader';
import PortfolioItem from '../Components/PortfolioItem';



// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

//Component SCSS
import 'scss/views/_portfolio.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

class Portfolio extends Component {

    constructor(props) {
        super(props);
        this.Swiper = React.createRef();
        this.state = {
            portfolioItems: [],
            isSliderOpen: false, //False for default state
            activeSlideIndex: null  //Null for no active slide
        };

        this.setActiveSlide = this.setActiveSlide.bind(this);
    }


    setActiveSlide(slideIndex){

        if(slideIndex === this.state.activeSlideIndex){
            this.setState({
                activeSlideIndex: null,
                isSliderOpen: false
            });

            this.lockSwiper(false);

        } else {

            this.setState({
                activeSlideIndex: slideIndex,
                isSliderOpen: true
            });

            this.lockSwiper(true);
        }
    }

    lockSwiper(lockState){
        this.Swiper.allowSlideNext = !lockState;
        this.Swiper.allowSlidePrev = !lockState;
        this.Swiper.allowTouchMove = !lockState;

        if(lockState === true){
            this.Swiper.mousewheel.disable();

        } else {
            this.Swiper.mousewheel.enable();
        }
    }

    setRef(obj){
        this.Swiper = obj;
    }

    fetchPortfolio = () => {
        const contentfulCient = contentful.createClient(ContentfulConfig);

        //Fetch Portfolio objects
        contentfulCient.getEntries({
            'content_type': 'accPortfolioItem',
            order: 'fields.renderOrder'
        })
            .then(entries => {
                this.setState({
                    portfolioItems: entries.items,
                });
            });
    };

    componentDidMount(){
        setTimeout(() => {
            this.fetchPortfolio();
        }, 1000);

    }

    render() {
        if (!this.state.portfolioItems.length){
            return (
                <div id="portfolio" className="page preloading">
                    <div className="inner-wrap">
                        <TextPreloader/>
                    </div>
                </div>

            )

        } else {

            const portfolioItems = this.state.portfolioItems.map((item, key) => {
                let isActive = false;
                if (key === this.state.activeSlideIndex) {
                    isActive = true;
                }
                return (

                    <SwiperSlide
                        key={key}
                        className={`${isActive ? 'active' : ''}`}
                    >
                        <PortfolioItem
                            content={item}
                            slideId={key}
                            isSliderOpen={this.state.isSliderOpen}
                            isActive={isActive}
                            activeSlideIndex={this.state.activeSlideIndex}
                            setActiveSlide={this.setActiveSlide}
                        />
                    </SwiperSlide>
                )
            });


            return (
                <div id="portfolio" className="page">
                    <Swiper
                        className={`${this.state.isSliderOpen ? 'active ' : ''} active-index-${this.state.activeSlideIndex}`}
                        spaceBetween={0}
                        loop={false}
                        updateOnWindowResize={true}
                        freeMode={false}
                        mousewheel={true}
                        pagination={{clickable: true}}
                        onSwiper={(swiper) => {
                            this.setRef(swiper);
                        }}
                        slidesPerView={1}
                        watchOverflow={false}
                        breakpoints={{
                            992: {
                                slidesPerView: 3
                            },

                            1400: {
                                slidesPerView: 4
                            }
                        }}
                    >
                        {portfolioItems}
                    </Swiper>
                </div>
            );
        }
    }
}
export default Portfolio;