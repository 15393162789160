import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';

//Child components
import BarPreloader from '../Utilities/BarPreloader';

//Component SCSS
import 'scss/components/_contact-form.scss';

class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            userEmail: '',
            userCompany: '',
            message: '',
            submitted: false,
            processing: false,
            formError: false
        };
    };

    handleCloseBtnClick = () => {
        this.props.toggleContactForm();
    };

    changeUserName = (event) => {
        this.setState({userName: event.target.value});
    };

    changeUserEmail = (event) => {
        this.setState({userEmail: event.target.value});
    };

    changeCompany = (event) =>{
        this.setState({userCompany: event.target.value});
    };

    changeMessage = (event) =>{
        this.setState({message: event.target.value});
    };

    validateForm = () =>{
        if(this.state.userName === '' || this.state.userEmail === '' || this.state.message === ''){
            return false;
        } else {
            return true;
        }
    };

    handleSubmit = (event) =>{
        this.setState({processing: true});
        emailjs.sendForm('gmail','template_khfyp6u', event.target, 'user_31WeBvmWalE8PR7Ss624c')
            .then((response) => {
                this.setState({
                    processing: false,
                    submitted: true
                });

                setTimeout(() => this.props.toggleContactForm(), 3000);

            }, (err) => {

                this.setState({
                    processing: false,
                    formError: true
                });
            });
        event.preventDefault();
    };

    render() {
        let validForm = this.validateForm();
        let defaultRender =

            <div className="content-block">
                <div className="intro-block">
                    <p>
                        Got a question? Comment? Critique? Want to discuss why I think Marvel is better than DC?
                        Drop me a message, and I'll get back to you as soon as I can.
                    </p>
                </div>

                <form id='submit' onSubmit={this.handleSubmit}>
                    <div className="input-row">
                        <label>
                            Your name:
                        </label>
                        <input type="text" name="userName" value={this.state.userName} onChange={this.changeUserName} />
                    </div>

                    <div className="input-row">
                        <label>
                            Your Email address:
                        </label>
                        <input type="text" name="userEmail" value={this.state.userEmail} onChange={this.changeUserEmail} />
                    </div>

                    <div className="input-row">
                        <label>
                            Your Company or organization:
                        </label>
                        <input type="text" name="userCompany" value={this.state.userCompany} onChange={this.changeCompany} />
                    </div>

                    <div className="input-row">
                        <label>
                            Your message:
                        </label>
                        <textarea name="message" value={this.state.message} onChange={this.changeMessage} />
                    </div>

                    <div className="control-row">
                        {this.state.formError === true &&
                            <div className="error-msg">
                                <p>
                                    Something went wrong :( <br/>Please try again
                                </p>
                            </div>
                        }
                        <button type="submit" className="btn-submit btn-angled" disabled={!validForm}>
                            <div className="inner">
                                <span className="txt">Submit</span>
                                <span className="icon icon-arrow-right2"></span>
                            </div>
                        </button>

                    </div>
                </form>
            </div>;

        if(this.state.submitted === true){
            defaultRender =
                <div className="content-block">
                    <div className="thank-you">
                        <p>Thanks! I'll aim to get back to you as soon as possible.</p>
                    </div>
                </div>
        }


        return (
            <div className="contact-form">
                <div className="component-inner">
                    <div className="inner-wrapper">
                        <button className="btn-close" onClick={this.handleCloseBtnClick}>
                            <span className="icon icon-cancel-circle"></span>
                        </button>

                        <div className="title-row">
                            <p className="title">Get in Touch</p>
                        </div>
                        {defaultRender}
                        {this.state.processing === true &&
                            <div className="processing-overlay">
                                <p>Sending your message...</p>
                                <BarPreloader/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default ContactForm;
