import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

//Component SCSS
import 'scss/components/_transition-mask.scss';

class TransitionMask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            layerCount: 10,
            animationClass: null
        }
    }

    playIn = () => {
        this.props.transitionStart();
        this.setState({
            animationClass: 'transition-in'
        })
    };

    playOut = () => {
        this.setState({
            animationClass: 'transition-out'
        })

        setTimeout(() => {
            this.reset();
        }, this.props.transitionDuration);
    };


    reset = () => {
        this.props.transitionEnd();
        this.setState({
            animationClass: null
        })
    };

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            // window.scrollTo(0, 0);
            this.playIn();

            setTimeout(() => {
                this.playOut();
            }, this.props.transitionDuration * 2);
        }
    }

    render() {

        let layersArr = [];
        for (let i = 0; i < this.state.layerCount; i++) {
            layersArr.push(
                {'layer': i}
            )
        }

        const layersRender = layersArr.map((item, key) => {
            return (
                <div key={key} className="layer-slice"></div>
            )
        });

        return (
            <div id="transition-mask" className={`${this.state.animationClass} `}>
                <div className="inner">
                    {layersRender}
                </div>
            </div>
        );
    }
}
export default withRouter(TransitionMask)