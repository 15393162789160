import React, { Component } from 'react';

//Component SCSS
import 'scss/components/_secondary-nav-links.scss';

class SecondaryNavLinks extends Component {

    handleBtnClick = () => {
        this.props.toggleContactForm();
    };

    render() {

        return (
            <ul className="secondary-nav-links">
                <li>
                    <button onClick={this.handleBtnClick}>
                        <span className="icon icon-mail2"></span>
                    </button>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/ben-watson-a8120a82/" target="_blank" rel="noopener noreferrer">
                        <span className="icon icon-linkedin"></span>
                    </a>
                </li>
            </ul>
        );
    }
}
export default SecondaryNavLinks;
