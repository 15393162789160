import React, { Component } from 'react';

class AboutContentIntro extends Component {

    render() {

        return (
            <section className="about-content-intro">
                <p>
                    I am an accomplished front end developer / ex-designer with 10+ years industry
                    experience. I possess a unique skill set encompassing the full spectrum of digital
                    media including both the design and development sides of website and digital marketing
                    production. Well-honed knowledge of languages and tools such as HTML, CSS, SASS/SCSS,
                    and javaScript, has given me the necessary
                    skills to excel in the development of large scale web campaigns and platforms for
                    clients such as Hamilton Island, Fox8, P&O, Microsoft and Xbox Australia.
                </p>
                <p>
                    I consider myself a bridge between design and tech; these technical skills, combined
                    with my creativity, keen eye for design and fluency in the full Adobe Suite, allow me
                    to breathe life into an otherwise static design, crafting engaging user interfaces that
                    function across a wide range of platforms and devices. I pride myself on developing
                    clean, semantic mark-up, lean, minified CSS and javascript and then integrating that
                    code with a wide gamut of platforms and backend technologies, including Sitecore,
                    Kentico and Umbraco.
                </p>
                <p>
                    I'm excited by opportunities to push my skills further, learn, grow and deliver even
                    better experiences across digital channels as both technology and our usage of it
                    continue to evolve.
                </p>
            </section>
        );
    }
}
export default AboutContentIntro;
