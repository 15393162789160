import React, { Component } from 'react';
import * as contentful from 'contentful'

//Config
import ContentfulConfig from '../Config/Contentful';

//Child Components
import TextPreloader from '../Utilities/TextPreloader';
import ScribblesGallery from '../Components/ScribblesGallery';

//SCSS
import 'scss/views/_scribbles.scss';


class Scribbles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scribbles: [],
        }
    }

    fetchScribbles = () => {
        const contentfulCient = contentful.createClient(ContentfulConfig);

        //Fetch Scribbles objects
        contentfulCient.getEntries({
            'content_type': 'accScribbles',
            order: 'fields.order'
        })
            .then(entries => {
                this.setState({
                    scribbles: entries.items,
                });
            });
    };

    componentDidMount(){
        this.fetchScribbles();
    }

    render() {
        let defaultLayout =
            <div className="row">
                <div className="col-12 col-md-6 offset-md-3">
                    <TextPreloader/>
                </div>
            </div>;

        let starBlock = <div className="star-block">
            <span className="icon icon-star-full"></span>
            <span className="icon icon-star-full"></span>
            <span className="icon icon-star-full"></span>
        </div>;

        if (this.state.scribbles.length > 0){
            defaultLayout = <ScribblesGallery
                scribbles={this.state.scribbles}
            />
        }

        return (
            <div id="scribbles">
                
                <div className="content-page">
                    <div className="inner-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-10 offset-md-1">
                                    <div className="title-row">
                                        {starBlock}
                                        <h1>Sketches, scribbles, doodles and dribbles</h1>
                                        {starBlock}
                                    </div>
                                    <div className="intro-row">
                                        <p>
                                            An assorted collection of smaller works in design, illustration and art,
                                            across both digital and traditional media. Some freelance, some personal,
                                            some the equivalent of a sketch on a cocktail napkin. Despite moving away from a design and
                                            illustration path in my career, I still love exploring and expressing my creativity.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {defaultLayout}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Scribbles;
