import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

//SCSS
import 'scss/components/_scribbles-gallery-modal.scss';

class ScribblesGalleryModal extends Component {
    render() {
        const slides = this.props.slides.map((slideItem, key) =>
            <SwiperSlide key={key}>
                <div className="inner">
                    <img src={slideItem.fields.featureImage.fields.file.url} alt={slideItem.fields.title}/>
                    <div className="desc">
                        <h3 className="title">{slideItem.fields.title}</h3>
                        <p>
                            {slideItem.fields.description}
                        </p>
                    </div>
                </div>
            </SwiperSlide>
        );

        return (
            <div className="scribbles-gallery-modal">
                <div className="close-wrapper">
                    <button className="btn-close" onClick={this.props.onClose}>
                        <span className="icon icon-cancel-circle"></span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>

                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={false}
                    updateOnWindowResize={true}
                    freeMode={false}
                    watchOverflow={false}
                    navigation
                    initialSlide={this.props.initialSlide}
                >
                    {slides}

                </Swiper>
            </div>
        );
    }
}
export default ScribblesGalleryModal;
