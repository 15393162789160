import React, { Component } from 'react';

//Assets
import Bitbucket from 'imgs/tool-logos/Bitbucket.png';
import Bootstrap from 'imgs/tool-logos/Bootstrap.png';
import Es6 from 'imgs/tool-logos/Es6.png';
import Gulp from 'imgs/tool-logos/Gulp.png';
import NPM from 'imgs/tool-logos/NPM.png';
import ReactLogo from 'imgs/tool-logos/React.png';
import SASS from 'imgs/tool-logos/SASS.png';
import Webstorm from 'imgs/tool-logos/Webstorm.png';

class PreferredTools extends Component {

    render() {

        return (
            <div className="preferred-tools">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h3>Preferred Toolset</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <img src={ReactLogo} alt="React JS"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={Es6} alt="ES6"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={Gulp} alt="Gulp"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={Webstorm} alt="Webstorm"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={Bitbucket} alt="Bitbucket"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={NPM} alt="NPM"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={Bootstrap} alt="Bootstrap 4"/>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img src={SASS} alt="SASS"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PreferredTools;
