import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Child Components
import PrimaryNav from './PrimaryNav';
import SecondaryNavLinks from './SecondaryNavLinks';

//Component SCSS
import 'scss/components/_global-header.scss';

//Assets
import logo from 'imgs/allcity-logo.png';

class GlobalHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navOpen: false,
        };
    }

    toggleNav = () => {
        this.setState({navOpen: !this.state.navOpen});
    };

    forceCloseNav = () => {
        this.setState({navOpen: false});
    };

    render() {

        return (
            <header id="global-header" className={`${this.state.navOpen ? 'nav-open' : ''}`}>
                <div className="inner">
                    <h1 className="logo">
                        <Link to="/" onClick={this.forceCloseNav}>
                            <img src={logo} alt="All City Creative"/>
                            <span className="sr-only">All City Creative - Ben Watson</span>
                        </Link>
                    </h1>

                    <button className={`btn-menu-trigger hamburger hamburger--minus ${this.state.navOpen ? 'is-active' : ''}`} type="button" onClick={this.toggleNav}> {/*Needs active class*/}
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>

                    <PrimaryNav
                        forceCloseNav={this.forceCloseNav}
                        toggleContactForm={this.props.toggleContactForm}
                    />
                    <nav id="secondary-nav">
                        <SecondaryNavLinks
                            toggleContactForm={this.props.toggleContactForm}
                        />
                    </nav>
                </div>
            </header>
        );
    }
}
export default GlobalHeader;
