import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

//Child components
import ScribblesGalleryModal from './ScribblesGalleryModal';

//SCSS
import 'scss/components/_scribbles-gallery.scss';

class ScribblesGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            modalIndex: 0
        }
    }

    openModal = (imgIndex) => {
        this.setState({
            modalOpen: true,
            modalIndex: imgIndex.key
        });
    };

    closeModal = () => {
        this.setState({
            modalOpen: false,
        });
    };

    render() {
        const galleryThumbs = this.props.scribbles.map((scribbleItem, key) => {
            const defaultImg = 'w=300&h=300';
            const wideImg = 'w=600&h=300';
            let imgConf = defaultImg;

            if(scribbleItem.fields.useWideTile === true){
                imgConf = wideImg;
            }

            if (scribbleItem.fields.focalPoint){
                imgConf = imgConf + '&f='+scribbleItem.fields.focalPoint;
            }

            return (
                <div className={` ${scribbleItem.fields.columnClass}`} key={key}>
                    <button className="tile" onClick={() => this.openModal({key})}>
                        <img src={`${scribbleItem.fields.featureImage.fields.file.url}?`+imgConf+`&fit=crop`} className="thumb default" alt=""/>
                    </button>
                </div>
            )
        });

        return (
            <div className="scribbles-gallery">

                <div className="container-fluid">
                    <div className="row">
                        {galleryThumbs}
                    </div>
                </div>

                <CSSTransition
                    in={this.state.modalOpen}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <ScribblesGalleryModal
                        onClose={this.closeModal}
                        initialSlide={this.state.modalIndex}
                        slides={this.props.scribbles}
                    />
                </CSSTransition>

            </div>
        );
    }
}
export default ScribblesGallery;
