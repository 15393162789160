import React, { Component } from 'react';

//SCSS
import 'scss/components/_testimonials.scss';

class Testimonials extends Component {

    render() {

        return (
            <section className="testimonials">
                <div className="testimonial-item">
                    <div className="main">
                        <p>
                            I worked with Ben for many years at Deepend and enjoyed the experience deeply. Ben is a talented
                            and creative front-end developer who deep-down is not afraid of a challenge even when it takes him
                            beyond his comfort zone. The most rewarding part of my role is seeing my developers flourish over
                            time: I feel both proud and grateful for the success case that is Watson.
                        </p>
                        <p>
                            I've seen him take on new languages, new platforms or new teamwork initiatives and lead them
                            through from original idea, R&D, prototyping, documentation, communication and leadership and
                            establishment of best practises. All while doing his day job - which is pleasantly surprising for a initially junior and very modest front-ender.
                        </p>
                        <p>
                            For example he owned the research and establishment of HTML5 / semantic-CSS3 in the team, and
                            led the change-management process from grass-roots. He helped me with long-term product developement. He tried his hand at being a scrum-master, which I think was successful due to his people skills and strong frienships he forms.
                        </p>
                        <p>
                            I could go on about pixel-perfection, eye for detail, design appreciation and all the other skills
                            which make him a first-class front-ender, but he's so much more than that.
                        </p>
                        <p>
                            If I had to sumarrise him in two words: "Conquers challenges".
                        </p>
                        <p>
                            I just look forward to working with him again one day.
                            <span className="icon icon-quotes-right"></span>
                        </p>
                    </div>
                    <div className="author">
                        <div className="img">
                            <img src="https://media-exp1.licdn.com/dms/image/C5103AQEcTANtdhNN_Q/profile-displayphoto-shrink_200_200/0?e=1609372800&v=beta&t=K_dKKEc2QZHCOokNFEuAIvoPfGfYazayIICuEzAbUYo" alt=""/>
                        </div>
                        <div className="desc">
                            <p className="name">Pip Jones </p>
                            <p className="title">Technical Director, Deepend (ex)</p>
                        </div>
                    </div>
                </div>

                <div className="testimonial-item">
                    <div className="main">
                        <p>
                            Ben is a well rounded developer with skills across a broad range of tools and technologies.
                            He's detail oriented and is never afraid to apply new methodologies based on specific requirements
                            in any given situation. Well respected and well liked by peers, managers and clients, he's a team
                            player and is a pleasure to collaborate with.
                        </p>
                        <p>
                            Ben has been a true asset to the agency's technical team over the past five years.
                            <span className="icon icon-quotes-right"></span>
                        </p>
                    </div>
                    <div className="author">
                        <div className="img">
                            <img src="https://media-exp1.licdn.com/dms/image/C4E03AQHoC26xWGDSwQ/profile-displayphoto-shrink_200_200/0?e=1609372800&v=beta&t=4bfMj6boMGab8ZQa00VSu90HvhePFL5RZ85RAznL-Q0" alt=""/>
                        </div>
                        <div className="desc">
                            <p className="name">Amer Iqbal </p>
                            <p className="title">Head of Strategy, Deepend (ex)</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Testimonials;
