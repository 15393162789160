import React, { Component } from 'react';
import Sketch from '../WebGL.js';

import 'scss/components/_webgl-image.scss';

class WebGLImage extends Component {
    constructor(props) {
        super(props);

        this.WebGL = null;
    }

    componentDidMount(){
        this.WebGL = new Sketch(
            {
                elementId: this.props.elementId,
                img: this.props.imageOriginal,
                imgDepth: this.props.imageDepth,
                horizontalThreshold: this.props.horizontalThreshold,
                verticalThreshold: this.props.verticalThreshold,
                movementThrottle: this.props.movementThrottle,
                parallax: this.props.parallax
            }
        );
    }

    componentWillUnmount (){
        this.WebGL.pause();
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.transitionStarted !== this.props.transitionStarted){
            if(nextProps.transitionStarted === true){
                this.WebGL.pause();
            } else {
                this.WebGL.play();
            }
        }
        return true;
    }

    render() {

        return (
            <div
                id={this.props.elementId}
                ref={this.node}
            >
            </div>
        );
    }
}
export default WebGLImage;
