import React, { Component } from 'react';

//Child Components
import FAQ from './FAQ';
import Currently from './Currently';

class QuickFacts extends Component {

    render() {

        return (
            <section className="quick-facts">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <FAQ/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <Currently/>
                    </div>
                </div>
            </section>
        );
    }
}
export default QuickFacts;

