import React, { Component } from 'react';

class GenericTextBlock extends Component {

    buildList = (listObj, key, listType) => {

        const listItems = listObj.content.map((item, itemKey) => {
            return (
                <li key={itemKey}>{item.content[0].content[0].value}</li>
            )
        });

        if (listType === 'unordered'){
            return (
                <ul key={key}>
                    {listItems}
                </ul>
            )
        } else if (listType === 'ordered'){
            return (
                <ol key={key}>
                    {listItems}
                </ol>
            )
        }
    }

    render() {
        const content = this.props.contentNodes.map((item, key) => {
            if (item.nodeType === 'paragraph'){
                return (
                    <p key={key}>{item.content[0].value}</p>
                )
            } else if (item.nodeType === 'unordered-list'){

                let listRender = this.buildList(item, key, 'unordered');
                return listRender;

            } else if (item.nodeType === 'ordered-list'){

                let listRender = this.buildList(item, key, 'ordered');
                return listRender;
            } else {
                return null;
            }
        });

        return (
            <div>
                {content}
            </div>
        );
    }
}
export default GenericTextBlock;
